import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PrivacyPolicySection__structures3 from '../components/__structures/PrivacyPolicySection__structures3';
import PrivacyPolicySectionFooters2 from '../components/footers/PrivacyPolicySectionFooters2';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [
    {
      cssText:
        '/\u002A\u0020General\u0020body\u0020styles\u0020\u002A/\nbody\u0020\u007B\n\u0020\u0020\u0020\u0020font\u002Dfamily\u003A\u0020Arial,\u0020sans\u002Dserif\u003B\n\u0020\u0020\u0020\u0020line\u002Dheight\u003A\u00201.6\u003B\n\u0020\u0020\u0020\u0020margin\u003A\u002020px\u003B\n\u0020\u0020\u0020\u0020color\u003A\u0020\u0023333\u003B\n\u007D\n\n/\u002A\u0020Headings\u0020\u002A/\nh2,\u0020h3,\u0020h4\u0020\u007B\n\u0020\u0020\u0020\u0020color\u003A\u0020\u00232A4D69\u003B\n\u007D\n\nh2\u0020\u007B\n\u0020\u0020\u0020\u0020border\u002Dbottom\u003A\u00202px\u0020solid\u0020\u00232A4D69\u003B\n\u0020\u0020\u0020\u0020padding\u002Dbottom\u003A\u00205px\u003B\n\u007D\n\nh3\u0020\u007B\n\u0020\u0020\u0020\u0020margin\u002Dtop\u003A\u002020px\u003B\n\u0020\u0020\u0020\u0020color\u003A\u0020\u00234A96AD\u003B\n\u007D\n\nh4\u0020\u007B\n\u0020\u0020\u0020\u0020margin\u002Dtop\u003A\u002010px\u003B\n\u0020\u0020\u0020\u0020color\u003A\u0020\u00234A96AD\u003B\n\u007D\n\n/\u002A\u0020Links\u0020\u002A/\na\u0020\u007B\n\u0020\u0020\u0020\u0020color\u003A\u0020\u00231A5276\u003B\n\u0020\u0020\u0020\u0020text\u002Ddecoration\u003A\u0020none\u003B\n\u007D\n\na\u003Ahover\u0020\u007B\n\u0020\u0020\u0020\u0020text\u002Ddecoration\u003A\u0020underline\u003B\n\u007D\n\n/\u002A\u0020Lists\u0020\u002A/\nul\u0020\u007B\n\u0020\u0020\u0020\u0020margin\u003A\u00200\u003B\n\u0020\u0020\u0020\u0020padding\u002Dleft\u003A\u002020px\u003B\n\u007D\n\nli\u0020\u007B\n\u0020\u0020\u0020\u0020margin\u002Dbottom\u003A\u002010px\u003B\n\u007D\n\n/\u002A\u0020Important\u0020text\u0020elements\u0020\u002A/\nstrong\u0020\u007B\n\u0020\u0020\u0020\u0020color\u003A\u0020\u0023145A32\u003B\n\u007D\n\n/\u002A\u0020Responsive\u0020design\u0020\u002A/\n\u0040media\u0020\u0028max\u002Dwidth\u003A\u0020768px\u0029\u0020\u007B\n\u0020\u0020\u0020\u0020body\u0020\u007B\n\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020padding\u003A\u002010px\u003B\n\u0020\u0020\u0020\u0020\u007D\n\u007D\n',
    },
  ],
  script: [],
};

export default function PrivacyPolicy() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <PrivacyPolicySection__structures3 />
      <PrivacyPolicySectionFooters2 />
    </React.Fragment>
  );
}

