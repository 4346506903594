import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSection__structures17 from '../components/__structures/IndexSection__structures17';
import IndexSection__structures16 from '../components/__structures/IndexSection__structures16';
import IndexSectionHeaders1 from '../components/headers/IndexSectionHeaders1';
import IndexSectionHowItWorks6 from '../components/how-it-works/IndexSectionHowItWorks6';
import IndexSectionFeatures15 from '../components/features/IndexSectionFeatures15';
import IndexSectionFeatures14 from '../components/features/IndexSectionFeatures14';
import IndexSectionFeatures10 from '../components/features/IndexSectionFeatures10';
import IndexSectionFeatures11 from '../components/features/IndexSectionFeatures11';
import IndexSectionFeatures12 from '../components/features/IndexSectionFeatures12';
import IndexSectionFeatures13 from '../components/features/IndexSectionFeatures13';
import IndexSectionTestimonials3 from '../components/testimonials/IndexSectionTestimonials3';
import IndexSectionFaqs2 from '../components/faqs/IndexSectionFaqs2';
import IndexSectionCta5 from '../components/cta/IndexSectionCta5';
import IndexSectionFooters4 from '../components/footers/IndexSectionFooters4';

const meta = {
  title: 'Transcribeo\u0020Voice\u0020Meeting\u0020Notes',
  meta: [
    {
      name: 'description',
      content:
        'Transcribeo\u0020employs\u0020an\u0020AI\u0020Meeting\u0020Assistant\u0020to\u0020transcribe\u0020meetings\u0020in\u0020real\u002Dtime,\u0020record\u0020audio,\u0020capture\u0020slides,\u0020extract\u0020action\u0020items,\u0020and\u0020generate\u0020AI\u002Ddriven\u0020meetings.',
    },
  ],
  link: [],
  style: [
    {
      cssText:
        '\u0040keyframes\u0020slideRightToLeft\u0020\u007B\n\u0020\u00200\u0025\u0020\u007B\n\u0020\u0020\u0020\u0020transform\u003A\u0020translateX\u0028100\u0025\u0029\u003B\n\u0020\u0020\u007D\n\u0020\u0020100\u0025\u0020\u007B\n\u0020\u0020\u0020\u0020transform\u003A\u0020translateX\u0028\u002D100\u0025\u0029\u003B\n\u0020\u0020\u007D\n\u007D\n\n.sliding\u002Dtestimonials\u0020\u007B\n\u0020\u0020display\u003A\u0020flex\u003B\n\u0020\u0020animation\u003A\u0020slideRightToLeft\u002030s\u0020linear\u0020infinite\u003B\n\u007D\n',
    },
  ],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSection__structures17 />
      <IndexSection__structures16 />
      <IndexSectionHeaders1 />
      <IndexSectionHowItWorks6 />
      <IndexSectionFeatures15 />
      <IndexSectionFeatures14 />
      <IndexSectionFeatures10 />
      <IndexSectionFeatures11 />
      <IndexSectionFeatures12 />
      <IndexSectionFeatures13 />
      <IndexSectionTestimonials3 />
      <IndexSectionFaqs2 />
      <IndexSectionCta5 />
      <IndexSectionFooters4 />
    </React.Fragment>
  );
}

