import React from 'react';

export default function AboutUsSection__structures2() {
    return (
        <React.Fragment>
            <>
                <section className="bg-blueGray-50 "><div className="container mx-auto overflow-hidden">
    <div className="flex items-center justify-between px-4 py-5 bg-blueGray-50">
      <div className="w-auto">
        <div className="flex flex-wrap items-center">
          <div className="w-auto mr-14">
            <a href="/">
              <img src="https://static.shuffle.dev/uploads/files/97/97f40e3702722b83d65d129377dfc5246792faa5/logo-transcribeo.svg" alt="" className="h-12" /></a>
          </div>
        </div>
      </div>
      <div className="w-auto">
        <div className="flex flex-wrap items-center">
          <div className="w-auto hidden lg:block">
            <ul className="flex items-center mr-16"><li className="mr-9 font-medium hover:text-gray-700"><a href="#">Features</a></li>
              <li className="mr-9 font-medium hover:text-gray-700"><a href="#">Resources</a></li>
              <li className="mr-9 font-medium hover:text-gray-700">
                <a href="#">Use Cases</a>
                <div className="md:inline-block" />
              </li>
              <li className="font-medium hover:text-gray-700"><a href="/pricing">Pricing</a></li>
            </ul></div>
          <div className="md:inline-block mr-4">
            <a href="/sign-in">
              <button className="py-2 px-4 w-auto text-black font-medium border border-gray-400 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 hover:bg-gray-100 transition ease-in-out duration-200" type="button" style={{borderColor: '#a9a9bc'}}>Login</button>
            </a>
          </div>
          <div className="md:inline-block">
            <a href="/sign-up">
              <button className="py-2 px-4 w-auto text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Get Started</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

