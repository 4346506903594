import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PricingSection__structures5 from '../components/__structures/PricingSection__structures5';
import PricingSectionPricing3 from '../components/pricing/PricingSectionPricing3';
import PricingSection__structures4 from '../components/__structures/PricingSection__structures4';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Pricing() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <PricingSection__structures5 />
      <PricingSectionPricing3 />
      <PricingSection__structures4 />
    </React.Fragment>
  );
}

