import React from 'react';

export default function IndexSectionCta5() {
    return (
        <React.Fragment>
            <>
                <section className="pt-24 pb-28 bg-white overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="text-center max-w-lg mx-auto">
      <h2 className="mb-5 text-6xl md:text-7xl font-bold font-heading text-center tracking-px-n leading-tight">Streamlining your meetings with AI has never been more straightforward</h2>
      <div className="mb-11 md:inline-block">
        <button className="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Add to Chrome  - It’s free!</button>
      </div>
      <div className="flex flex-wrap items-center justify-center -m-1">
        <div className="w-auto p-1">
          <div className="flex flex-wrap">
            <div className="w-auto">
              <img src="flaro-assets/images/cta/avatar-circle.png" alt="" />
            </div>
            <div className="w-auto -ml-3">
              <img src="flaro-assets/images/cta/avatar-circle2.png" alt="" />
            </div>
            <div className="w-auto -ml-3">
              <img src="flaro-assets/images/cta/avatar-circle3.png" alt="" />
            </div>
          </div>
        </div>
        <div className="w-auto p-1">
          <p className="text-gray-600 font-medium">
            <span>Join</span>
            <span className="font-bold">10K+</span>
            <span>other happy users</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

