import React from 'react';

export default function IndexSectionFeatures12() {
    return (
        <React.Fragment>
            <>
                <section className="pt-24 pb-28 bg-white overflow-hidden"><div className="container px-4 mx-auto">
    <h2 className="mb-7 text-6xl md:text-8xl xl:text-10xl font-bold font-heading text-center tracking-px-n leading-none">
      Automate workflows to <span className="text-indigo-600">increase productivity</span> for your team
    </h2>
    <div className="flex justify-center">
      <div className="inline-block">
        <img className="mb-11 mx-auto transform hover:translate-y-3 transition ease-in-out duration-1000" src="https://static.shuffle.dev/uploads/files/97/97f40e3702722b83d65d129377dfc5246792faa5/65cab4de2068ffdd5c831cfe-automate-main-at-2x-p-1600.jpg" alt="" />
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

