import React from 'react';

export default function IndexSectionFeatures15() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-16 bg-white overflow-hidden "><img className="absolute left-0 top-0" src="flaro-assets/images/features/elipse.svg" alt="" /><div className="container px-4 mx-auto">
    <div className="flex flex-wrap items-center -m-8">
      <div className="w-full md:w-1/2 p-8">
        <h2 className="text-6xl font-bold tracking-tight">
          <span className="text-indigo-600">Never miss a detail</span>
          with AI Meeting Notes
        </h2>
        <p className="font-sans text-lg text-gray-900 leading-relaxed md:max-w-lg">TranscribeoPilot seamlessly joins Zoom, Google Meet, and Microsoft Teams meetings to automatically record notes, freeing everyone to participate actively. Follow along live on the web or via the iOS or Android app.</p>
      </div>
      <div className="w-full md:w-1/2 p-8">
        <img className="transform hover:-translate-y-16 transition ease-in-out duration-1000" src="https://static.shuffle.dev/uploads/files/97/97f40e3702722b83d65d129377dfc5246792faa5/65ca96699a5d2238f2ed8496-homepage-never-miss-a-detail-at-2x-p-800.jpg" alt="" /></div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

