import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import TestSectionHeaders3 from '../components/headers/TestSectionHeaders3';
import TestSectionHeaders1 from '../components/headers/TestSectionHeaders1';
import TestSectionFooters2 from '../components/footers/TestSectionFooters2';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Test() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <TestSectionHeaders3 />
      <TestSectionHeaders1 />
      <TestSectionFooters2 />
    </React.Fragment>
  );
}

