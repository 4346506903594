import React from 'react';

export default function IndexSectionTestimonials3() {
    return (
        <React.Fragment>
            <>
                <section className="relative pt-24 pb-32 bg-white overflow-hidden"><img className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" src="flaro-assets/images/testimonials/gradient3.svg" alt="" /><div className="relative z-10 container px-4 mx-auto">
    <div className="flex flex-wrap justify-between items-end -m-2 mb-12">
      <div className="w-auto p-2">
        <h2 className="text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">What our users say</h2>
      </div>
      <div className="w-auto p-2"><a className="font-sans font-medium text-indigo-600 hover:text-indigo-700 leading-10" href="#">See all our reviews</a></div>
    </div>
    <div className="flex flex-wrap -m-2 sliding-testimonial">
      <div className="w-full md:w-1/2 lg:w-1/4 p-2">
        <div className="px-8 py-6 h-full bg-white bg-opacity-80 rounded-3xl">
          <div className="flex flex-col justify-between h-full">
            <div className="mb-7 block">
              <div className="flex flex-wrap -m-0.5 mb-6">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
              </div>
              <h3 className="mb-6 text-lg font-bold font-heading">“We love it.”</h3>
              <p className="text-lg font-medium">I've been using this app for quite some time, and honestly, I'm blown away by the accuracy of the transcriptions. I couldn't be happier with the results!</p>
            </div>
            <div className="block">
              <p className="font-bold">James Patterson</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/4 p-2">
        <div className="px-8 py-6 h-full bg-white bg-opacity-80 rounded-3xl">
          <div className="flex flex-col justify-between h-full">
            <div className="mb-7 block">
              <div className="flex flex-wrap -m-0.5 mb-6">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
              </div>
              <h3 className="mb-6 text-lg font-bold font-heading">“Beautiful layouts”</h3>
              <p className="text-lg font-medium">Until you try it, you won't realize just how much time you can save and how significantly it can boost your efficiency and productivity. Absolutely awesome!</p>
            </div>
            <div className="block">
              <p className="font-bold">Emma Thompson</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/4 p-2">
        <div className="px-8 py-6 h-full bg-white bg-opacity-80 rounded-3xl">
          <div className="flex flex-col justify-between h-full">
            <div className="mb-7 block">
              <div className="flex flex-wrap -m-0.5 mb-6">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
              </div>
              <h3 className="mb-6 text-lg font-bold font-heading">“Excellent Support”</h3>
              <p className="text-lg font-medium">The support team is truly remarkable! They’re always available and incredibly helpful, making sure we get the most out of our transcriptions</p>
            </div>
            <div className="block">
              <p className="font-bold">Lucas White</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/4 p-2">
        <div className="px-8 py-6 h-full bg-white bg-opacity-80 rounded-3xl">
          <div className="flex flex-col justify-between h-full">
            <div className="mb-7 block">
              <div className="flex flex-wrap -m-0.5 mb-6">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" /></svg></div>
              </div>
              <h3 className="mb-6 text-lg font-bold font-heading">“Easy to setup”</h3>
              <p className="text-lg font-medium">The ease of use is phenomenal! This app makes it so easy to manage meeting transcriptions, even for those of us who aren't tech-savvy.</p>
            </div>
            <div className="block">
              <p className="font-bold">Sophia Lee</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

