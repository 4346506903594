import React from 'react';

export default function IndexSectionFeatures13() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 bg-white overflow-hidden bs-section-dragged "><div className="container px-4 mx-auto">
    <div className="flex flex-wrap items-center -m-8">
      <div className="w-full md:w-1/3 p-8">
        <div className="md:max-w-sm">
          <div className="flex flex-wrap items-center -m-3">
            <div className="w-auto p-3">
              <div className="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                <img src="flaro-assets/images/features/chat.svg" alt="" />
              </div>
            </div>
            <div className="flex-1 p-3">
              <h3 className="text-xl font-semibold">Assigns action items</h3>
            </div>
          </div>
        </div>
        <p className="mb-4 text-base mt-2.5">Transcribeo automatically identifies and assigns action items to team members, saving the meeting leader time and ensuring the team stays aligned on next steps.</p>
      </div>
      <div className="w-full md:w-1/3 p-8">
        <div className="md:max-w-sm">
          <div className="flex flex-wrap items-center -m-3">
            <div className="w-auto p-3">
              <div className="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                <img src="flaro-assets/images/features/layers.svg" alt="" />
              </div>
            </div>
            <div className="flex-1 p-3">
              <h3 className="text-xl font-semibold">Posts to Slack</h3>
            </div>
          </div>
        </div>
        <p className="mb-4 text-base mt-2.5">Transcribeo automatically distributes meeting notes and summaries to teammates via email and directly in the team's Slack channel.</p>
      </div>
      <div className="w-full md:w-1/3 p-8">
        <div className="md:max-w-sm">
          <div className="flex flex-wrap items-center -m-3">
            <div className="w-auto p-3">
              <div className="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                <img src="flaro-assets/images/features/replace.svg" alt="" />
              </div>
            </div>
            <div className="flex-1 p-3">
              <h3 className="text-xl font-semibold">Integrates seamlessly</h3>
            </div>
          </div>
        </div>
        <p className="mb-4 text-base mt-2.5">Transcribeo seamlessly integrates with everyday tools your team uses, including Salesforce, HubSpot, Egnyte, Amazon S3, Snowflake, and Microsoft SharePoint.</p>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

