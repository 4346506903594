import React from 'react';

export default function IndexSection__structures17() {
    return (
        <React.Fragment>
            <>
                <div className="container mx-auto bg-blueGray-50" />


            </>
        </React.Fragment>
    );
}

