import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import SignInPage from './pages/Sign-in.js';
import SignUpPage from './pages/Sign-up.js';
import PricingPage from './pages/Pricing.js';
import AboutUsPage from './pages/About-us.js';
import TermsOfServicePage from './pages/Terms-of-service.js';
import PrivacyPolicyPage from './pages/Privacy-policy.js';
import TestPage from './pages/Test.js';

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
         
      <Route path="/signin" element={<SignInPage />} />
         
      <Route path="/signup" element={<SignUpPage />} />
         
      <Route path="/pricing" element={<PricingPage />} />
         
      <Route path="/aboutus" element={<AboutUsPage />} />
         
      <Route path="/termsofservice" element={<TermsOfServicePage />} />
         
      <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
         
      <Route path="/test" element={<TestPage />} />
    </Routes>
  );
}

export default App;
