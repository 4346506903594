import React from 'react';

export default function IndexSectionHeaders1() {
    return (
        <React.Fragment>
            <>
                <div className="overflow-hidden pt-16">
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap -m-8">
      <div className="w-full md:w-1/2 p-8">
        <h1 className="mb-6 text-6xl md:text-8xl lg:text-10xl font-bold font-heading md:max-w-xl leading-none">Focus on the Meeting, Leave the Notes to AI.</h1>
        <p className="mb-11 text-lg text-gray-900 font-medium md:max-w-md">Spend less time writing, polishing, and sharing notes and more time on the work only you can do.</p>
        <div className="flex flex-wrap -m-2.5 mb-20">
          <div className="w-full md:w-auto p-2.5">
            <div className="block">
              <a href="https://chromewebstore.google.com/detail/otterai-record-and-transc/bnmojkbbkkonlmlfgejehefjldooiedp?hl=en">
                <button className="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Add to Chrome - It’s free!</button>
              </a>
            </div>
          </div>
          <img src="https://static.shuffle.dev/uploads/files/97/97f40e3702722b83d65d129377dfc5246792faa5/icon-mettings.svg" alt="" /><div className="w-full md:w-auto p-2.5">
            <div className="block" />
          </div>
        </div>
        <p className="mb-6 text-sm text-gray-500 font-semibold uppercase">Trusted and loved by 100+ tech first teams</p>
        <div className="flex flex-wrap -m-3">
          <div className="w-auto p-3">
            <img src="flaro-assets/logos/brands/brand.png" alt="" /></div>
          <div className="w-auto p-3">
            <img src="flaro-assets/logos/brands/brand2.png" alt="" /></div>
          <div className="w-auto p-3">
            <img src="flaro-assets/logos/brands/brand3.png" alt="" /></div>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-8">
        <img className="transform hover:-translate-y-16 transition ease-in-out duration-1000" src="https://static.shuffle.dev/uploads/files/97/97f40e3702722b83d65d129377dfc5246792faa5/65eabe50c79fe3d4b383e416-Transcribe-meetings-live-min-p-800.png" alt="" /></div>
    </div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

