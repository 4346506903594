import React from 'react';

export default function AboutUsSectionTeams1() {
    return (
        <React.Fragment>
            <>
                <section className="py-36 bg-white overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap md:max-w-xl lg:max-w-7xl mx-auto">
      <div className="w-full md:w-1/2">
        <div className="flex flex-col justify-between h-full">
          <div className="mb-16 md:max-w-md mx-auto">
            <p className="mb-6 font-sans text-sm text-indigo-600 font-semibold uppercase">Meet The Team</p>
            <h2 className="mb-8 text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none">The team behind Transcribeo</h2>
            <p className="text-lg text-gray-600 font-medium leading-normal md:max-w-sm">Meet the dedicated professionals at Transcribeo who bring your transcripts to life. Our team combines expertise in technology, language, and customer service to ensure every transcript is accurate, timely, and useful for your needs.</p>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
              <img className="mx-auto" src="flaro-assets/images/team/team-circle3.png" alt="" />
            </div>
            <div className="w-full lg:w-1/2">
              <img className="mx-auto" src="flaro-assets/images/team/team-circle4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2">
        <div className="flex flex-col justify-end h-full">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
              <img className="mx-auto" src="flaro-assets/images/team/team-circle.png" alt="" />
            </div>
            <div className="w-full lg:w-1/2">
              <img className="mx-auto" src="flaro-assets/images/team/team-circle2.png" alt="" />
            </div>
            <div className="w-full lg:w-1/2">
              <img className="mx-auto" src="flaro-assets/images/team/team-circle5.png" alt="" />
            </div>
            <div className="w-full lg:w-1/2">
              <img className="mx-auto" src="flaro-assets/images/team/team-circle6.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

