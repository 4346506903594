import React from 'react';

export default function IndexSectionFeatures14() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-16 bg-white overflow-hidden">{/* Adjusted py-16 */}<div className="container px-4 mx-auto">
    <div className="flex flex-wrap items-center -m-8">
      <div className="w-full md:w-1/2 p-8">
        <img className="transform hover:-translate-y-16 transition ease-in-out duration-1000" src="https://static.shuffle.dev/uploads/files/97/97f40e3702722b83d65d129377dfc5246792faa5/65ca966951ad96cb18e14d72-homepage-condense-at-2x-p-800.jpg" alt="" /></div>
      <div className="w-full md:w-1/2 p-8">
        <h2 className="mb-6 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">
          Condense a 1 hour meeting into
          <span className="text-indigo-600">30 seconds</span>
        </h2>
        <p className="font-sans text-lg text-gray-900 leading-relaxed md:max-w-lg">Forgot what happened in a one-hour team meeting? Transcribeo creates a 30-second summary to keep everyone informed.</p>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

