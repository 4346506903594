import React from 'react';

export default function IndexSectionFeatures10() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-16 bg-white overflow-hidden "><div className="container px-4 mx-auto">
    <div className="flex flex-wrap items-center -m-8">
      <div className="w-full md:w-1/2 p-8">
        <h2 className="mb-6 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">
          Skip the tedious task of composing
          <span className="text-indigo-600">action item</span>
          emails
        </h2>
        <p className="font-sans text-lg text-gray-900 leading-relaxed md:max-w-lg">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.</p>
      </div>
      <div className="w-full md:w-1/2 p-8">
        <img className="transform hover:-translate-y-16 transition ease-in-out duration-1000" src="https://static.shuffle.dev/uploads/files/97/97f40e3702722b83d65d129377dfc5246792faa5/65ca9669dc0896b5f3030cae-homepage-skip-at-2x-p-800.jpg" alt="" />
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

